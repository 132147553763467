/* eslint no-shadow: 0, no-negated-condition: 0 */
/* eslint react/sort-comp: 0 */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchListing,
  setActiveModal,
  setBuyerLocalization,
  fetchCartProduct,
  clearHttpError,
  fetchCartItems
} from 'redux/actions';
import { Cookies } from 'react-cookie';
import { bpProps } from 'utils/responsiveUtils';
import ListingGallery from 'components/ListingGallery';
import ListingDetails from 'components/ListingDetails';
import MainLayout from 'containers/MainLayout';
import RelatedGrid from 'components/RelatedGrid';
import PrivateListing from 'components/PrivateListing';
import { generateBaseSku } from 'utils/productUtils';
import { getCartLineItems, getUnavailableItems, pruneCart } from 'utils/cartUtils';
import { listingUrl } from 'utils/urlGeneration';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import propTypes from 'prop-types';
import queryString from 'query-string';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { TrackJS } from 'trackjs';
import tracker from 'utils/tracking';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import './ListingPage.scss';
import tiktok from 'utils/tracking/pixels/tiktok';
import { getTrafficSource, trackProductPage } from 'utils/tracking/springAnalytics';
import Products from 'components/Products';
import { scrollTo } from 'utils/scrollTo';
import SkeletonListingPage from './SkeletonListingPage';

const TIKTOK_UTM_SOURCE = 'TTEC';

export function ListingPageWrapper(WrappedComponent) {
  class BaseComponent extends Component {
    state = {
      pixelSent: false
    }

    componentDidMount() {
      this.useBuyerLocalization();
      this.dropTiktokPixelAndCookie();
      this.fetchListings(); // Async, but no need to wait in a lifecycle fn
      this.fetchCart();
    }

    async componentDidUpdate(prevProps) {
      const { slug } = prevProps.match.params;
      const {
        match,
        storeListings,
        httpError,
        dispatchClearHttpError,
        history,
        cartProducts,
        inventory,
        location
      } = this.props;
      const { pixelSent } = this.state;
      const newSlug = match.params.slug;
      const campaignRootId = get(this.selectedListingProduct(), 'teespringId');

      if (!pixelSent && campaignRootId) {
        this.trackItemView();
        this.setState({ pixelSent: true });
      }

      if (slug !== newSlug && !storeListings[newSlug]) {
        await this.fetchListings();
      }

      // Make sure we're scrolled to the top when loading into a new location with a new product
      if (history.action !== 'POP' && location?.key !== prevProps.location?.key) {
        const prevProduct = queryString.parse(prevProps.location?.search || '')?.product;
        const currProduct = queryString.parse(location?.search || '')?.product;
        if (prevProduct !== currProduct) {
          this.scrollToTop(0);
        }
      }

      // Unavailable item detected in cart upon hitting "Checkout" in CartConfirmation
      if (storeListings.isFetchingForUnavailability) {
        const { userCart } = this.props;
        const prevWasFetching = prevProps.storeListings.isFetchingCart;
        const currIsDoneFetching = !storeListings.isFetchingCart;
        const doneFetching = prevWasFetching && currIsDoneFetching;

        if (doneFetching) {
          const currUserCartLength = Object.keys(pruneCart(userCart)).length;
          const currValidUserCartItemsLength = getCartLineItems(userCart, cartProducts, inventory).length;
          const detectedUnavailableItem = currUserCartLength > currValidUserCartItemsLength;

          if (detectedUnavailableItem) this.triggerUnavailableItemsModal();
        }
      }

      if (httpError.status === 400 || httpError.status === 404) {
        if (httpError.name === 'ProductError') {
          dispatchClearHttpError();
          await this.fetchListings();
          history.push(listingUrl(newSlug));
        } else {
          history.push('/listing-not-found');
        }
      }
    }

    triggerUnavailableItemsModal() {
      const {
        userCart, storeListings, dispatchSetActiveModal, storeId, inventory
      } = this.props;
      const unavailableItems = getUnavailableItems(userCart, storeListings, inventory);
      if (unavailableItems && unavailableItems.length > 0) {
        setTimeout(() => {
          dispatchSetActiveModal('cart-unavailable-items', { items: unavailableItems, storeId });
        }, 400);
      }
    }

    componentWillUnmount() {
      if (this.validateUtmSource(TIKTOK_UTM_SOURCE)) {
        tiktok.removeTiktokPixel();
      }
    }

    onProductChange = (newProductIndex) => {
      const { match, history } = this.props;
      const newActiveProductId = this.productIndexToProductId(newProductIndex);
      const listingSlug = match.params.slug;

      history.push(listingUrl(listingSlug, newActiveProductId));
    };

    onProductVariationChange = (newProductVariationIndex, list) => {
      const { color, variationId } = this.selectedVariation(newProductVariationIndex);
      tracker.track('product_page.different_color.clicked', { colorIndex: newProductVariationIndex, color, colorId: variationId });
      const { match, history } = this.props;
      const { slug: listingSlug } = match.params;
      const activeProductIndex = this.activeProductIndex();
      const activeSizeIndex = this.activeProductSizeIndex();

      if (get(this.selectedVariation(), `sizes[${activeSizeIndex}]`)) {
        history.push(listingUrl(
          listingSlug,
          this.productIndexToProductId(activeProductIndex),
          this.productVariationIndexToProductVariationId(newProductVariationIndex),
          this.productSizeIndexToProductSizeId(newProductVariationIndex, activeSizeIndex)
        ),
        { list });
      } else {
        // Reset active size to 0 if new variation does not include previously active size
        history.push(
          listingUrl(
            listingSlug,
            this.productIndexToProductId(activeProductIndex),
            this.productVariationIndexToProductVariationId(
              newProductVariationIndex
            ),
            this.productSizeIndexToProductSizeId(newProductVariationIndex, 0)
          )
        );
      }
    }

    onProductSizeChange = (newProductSizeIndex, list) => {
      const { match, history } = this.props;
      const { slug: listingSlug } = match.params;
      const activeProductIndex = this.activeProductIndex();
      const activeProductVariationIndex = this.activeProductVariationIndex();
      const size = get(this.selectedVariation(), `sizes[${newProductSizeIndex}]`);
      tracker.track('product_page.different_size.clicked', { size });

      history.push(listingUrl(
        listingSlug,
        this.productIndexToProductId(activeProductIndex),
        this.productVariationIndexToProductVariationId(activeProductVariationIndex),
        this.productSizeIndexToProductSizeId(activeProductVariationIndex, newProductSizeIndex)
      ),
      { list });
    }

    dropTiktokPixelAndCookie() {
      this.initializeTiktokPixel();
      this.dropTiktokCookie();
    }

    dropTiktokCookie() {
      if (this.validateUtmSource(TIKTOK_UTM_SOURCE)) {
        const { cookies } = this.props;
        const partnerCookie = cookies.get('_teespring_partner_attribution');
        if (
          !partnerCookie
          || (partnerCookie && partnerCookie.partner !== 'tiktok')
        ) {
          const expires = new Date();
          expires.setTime(expires.getTime() + 2 * 60 * 60 * 1000);
          const cookieOptions = window.location.host === 'teespring.com'
            ? {
              path: '/',
              expires,
              domain: '.teespring.com'
            }
            : {
              path: '/',
              expires
            };
          cookies.set(
            '_teespring_partner_attribution',
            { partner: 'tiktok' },
            cookieOptions
          );
        }
      }
    }

    initializeTiktokPixel() {
      if (this.validateUtmSource(TIKTOK_UTM_SOURCE)) {
        tiktok.initializeTiktokPixelHelper();
      }
    }

    useBuyerLocalization() {
      const {
        setBuyerLocalization,
        location: { search }
      } = this.props;
      const { region, currency, locale } = queryString.parse(search);
      const buyerLocalization = {
        buyer_region: region,
        buyer_currency: currency,
        buyer_locale: locale
      };
      if (region || currency || locale) {
        setBuyerLocalization(omitBy(buyerLocalization, isUndefined));
      }
    }

    async fetchCart() {
      const {
        fetchCartItems
      } = this.props;
      // In order to show a Cart Confirmation of the purchase,
      // in case the buyer decides to Add the current listing to their cart,
      // is that the Cart content data needs to already be available.
      await fetchCartItems();
    }

    async fetchListings() {
      const {
        match,
        fetchListing,
        location
      } = this.props;
      const { slug } = match.params;
      const { product } = queryString.parse(location.search, {
        ignoreQueryPrefix: true
      });

      // TODO: Handle case for error and wrong url
      await fetchListing(slug, product);
    }

    handleAddToCart = (productData) => {
      const {
        match,
        cookies,
        dispatchFetchCartProduct,
        location
      } = this.props;
      const { slug } = match.params;
      const { updateCart } = this.props;
      const sku = generateBaseSku(productData);
      const cartData = { ...productData, slug, sku };
      const partnerCookie = cookies.get('_teespring_partner_attribution');
      const { product } = queryString.parse(location.search, {
        ignoreQueryPrefix: true
      });

      tracker.track(
        'product_page.add_to_cart.clicked',
        tiktok.trackPartnersCookieInParams(partnerCookie)
      );

      dispatchFetchCartProduct(slug, product);
      updateCart(sku, cartData);
    };

    integerQueryParam(paramName) {
      const {
        location: { search }
      } = this.props;
      return parseInt(queryString.parse(search)[paramName]);
    }

    activeProductIndex() {
      const activeProductIdFromUrl = this.integerQueryParam('product');
      return this.productIdToProductIndex(activeProductIdFromUrl) || 0;
    }

    activeProductVariationIndex() {
      const activeProductVariationIdFromUrl = this.integerQueryParam(
        'variation'
      );
      return activeProductVariationIdFromUrl
        ? this.productVariationIdToProductVariationIndex(
          activeProductVariationIdFromUrl
        )
        : 0;
    }

    getDefaultProductSizeIndex() {
      try {
        const variation = this.selectedVariation();
        const availableSizesWithId = get(variation, 'availableSizesWithId');
        const availableSizeId = get(availableSizesWithId?.[0], 'id') || undefined;
        if (availableSizeId) {
          const sizes = get(variation, 'sizes');
          const index = sizes?.findIndex(s => s.id === availableSizeId);
          if (index !== undefined && index >= 0) {
            return index;
          }
        }
      } catch (err) {
        TrackJS.track(err);
      }
      return 0;
    }

    activeProductSizeIndex() {
      const activeProductSizeIdFromUrl = this.integerQueryParam('size');
      return activeProductSizeIdFromUrl
        ? this.productSizeIdToProductSizeIndex(activeProductSizeIdFromUrl)
        : this.getDefaultProductSizeIndex();
    }

    productIdToProductIndex(productId) {
      const foundIndex = this.listingProducts()
        ? this.listingProducts().findIndex(
          productData => productData.productId === productId
        )
        : 0;
      return foundIndex >= 0 ? foundIndex : 0;
    }

    productVariationIdToProductVariationIndex(productVariationId) {
      const product = this.selectedListingProduct();
      const variations = this.getVariations(product);
      const foundIndex = variations.findIndex(
        variation => get(variation, 'variationId') === productVariationId
      );
      return foundIndex >= 0 ? foundIndex : 0;
    }

    productSizeIdToProductSizeIndex(productSizeId) {
      const variation = this.selectedVariation();
      const foundIndex = get(variation, 'sizes', []).findIndex(
        size => size.id === productSizeId
      );
      return foundIndex >= 0 ? foundIndex : 0;
    }

    productIndexToProductId(productIndex) {
      const listing = this.selectedListingProduct();
      const products = this.listingProducts();

      if (!isEmpty(products)) {
        return get(products, productIndex).productId;
      }

      return has(listing, 'primaryProductId')
        ? get(listing, 'primaryProductId')
        : get(listing, 'productId');
    }

    productVariationIndexToProductVariationId(productVariationIndex) {
      const { storeListings, match } = this.props;
      const { slug } = match.params;
      const listing = get(storeListings, `${slug}`);
      const products = this.listingProducts();

      return isEmpty(products)
        ? listing.primaryProduct[productVariationIndex].variationId
        : products[this.activeProductIndex()].variations[productVariationIndex]
          .variationId;
    }

    productSizeIndexToProductSizeId(productVariationIndex, productSizeIndex) {
      const selectedProduct = this.selectedListingProduct();

      if (has(selectedProduct, 'primaryProduct')) {
        return selectedProduct.primaryProduct[productVariationIndex].sizes[
          productSizeIndex
        ].id;
      } else {
        return selectedProduct.variations[productVariationIndex].sizes[
          productSizeIndex
        ].id;
      }
    }

    listingProducts() {
      const { storeListings, match } = this.props;
      const { slug } = match.params;
      return get(storeListings[slug], 'products', []);
    }

    selectedVariation(newVariationIndex) {
      const product = this.selectedListingProduct();
      const variations = this.getVariations(product);
      const activeIndex = this.activeProductVariationIndex();
      return get(variations, [newVariationIndex ?? activeIndex]);
    }

    selectedListing() {
      const { storeListings, match } = this.props;
      const { slug } = match.params;
      return get(storeListings, slug);
    }

    selectedListingProduct() {
      const { storeListings, match } = this.props;
      const { slug } = match.params;
      const products = this.listingProducts();

      return isEmpty(products)
        ? get(storeListings, slug)
        : get(products, this.activeProductIndex());
    }

    getVariations = (product) => {
      return has(product, 'variations')
        ? get(product, 'variations', [])
        : get(product, 'primaryProduct', []);
    };

    trackItemView = () => {
      const { match, storeListings, cookies } = this.props;
      const { slug } = match.params;
      const selectedProduct = storeListings[slug];
      const partnerCookie = cookies.get('_teespring_partner_attribution');
      const campaignRootId = get(this.selectedListingProduct(), 'teespringId');
      const productId = get(this.selectedListingProduct(), 'productId');

      if (selectedProduct) {
        tracker.track('product_page.viewed', {
          campaignRootId,
          productId,
          ...tiktok.trackPartnersCookieInParams(partnerCookie)
        });
        ReactGA.event(
          {
            category: 'engagement',
            action: 'view_item',
            label: JSON.stringify({
              sku: get(selectedProduct, 'sku'),
              price: get(selectedProduct, 'price')
            })
          },
          ['default', 'client']
        );
        ReactPixel.pageView();
        ReactPixel.track('ViewContent', {
          content_type: 'product',
          content_name: get(selectedProduct, 'title'),
          content_url: get(selectedProduct, 'url'),
          content_ids: [`${campaignRootId}-${productId}`],
          value: get(selectedProduct, 'price')
        });
        trackProductPage(get(selectedProduct, 'listingId'));
        cookies.set(
          'utm_source',
          getTrafficSource()
        );
      }
    };

    handleProductChange = (index) => {
      const { cookies } = this.props;
      const partnerCookie = cookies.get('_teespring_partner_attribution');
      const trackPartnersCookieInParams = tiktok.trackPartnersCookieInParams(partnerCookie);
      this.onProductChange(index);
      tracker.track(
        'product_page.other_products.clicked',
        trackPartnersCookieInParams
      );
      // Timeout is used to prevent scroll event from being canceled
      setTimeout(() => {
        this.scrollToTop();
        this.trackItemView();
      }, 200);
    };

    validateUtmSource(source) {
      const {
        location: { search }
      } = this.props;
      const params = queryString.parse(search);
      return params.utm_source === source;
    }

    getThumbnails = (listing, activeVariationIndex) => {
      const product = has(listing, 'primaryProduct')
        ? get(listing.primaryProduct, activeVariationIndex)
        : listing;

      const variations = get(product, 'variations', []);

      if (!isEmpty(variations)) {
        return get(get(product.variations, activeVariationIndex), 'images');
      } else {
        return get(product, 'images');
      }
    };

    generateThumbnailSrc = (thumbnail) => {
      if (thumbnail.includes('width')) {
        return thumbnail;
      }
      const imgParts = thumbnail.split('/').splice(2, 4).join('/');
      return `https://${imgParts}/560/560.jpg`;
    };

    /**
     * Scroll the listing page to the top
     *
     * @param {number | undefined} duration duration in seconds for the scroll tween, passing undefined will use a system default
     */
    scrollToTop = (duration = undefined) => {
      // Note this is not recursion
      scrollTo('.listing-content', undefined, undefined, duration);
    }

    render() {
      const {
        storeData,
        location,
        stores,
        userCart,
        match,
        styles,
        bpIsGT,
        bpIsLT,
        setClass,
        httpError,
        history,
        getStyles,
        storeListings
      } = this.props;

      const { slug } = match.params;
      const visibility = get(storeListings, `${slug}.products[0].visibility`, 'public');
      const listing = this.selectedListing();
      const listingProduct = this.selectedListingProduct();

      if (!storeListings[slug]) {
        return (
          <MainLayout location={ location } storeData={ storeData }>
            <div className="content">
              <SkeletonListingPage />
            </div>
          </MainLayout>
        );
      }

      // Data loaded
      const activeProductIndex = this.activeProductIndex();
      const activeVariationIndex = this.activeProductVariationIndex();
      const activeSizeIndex = this.activeProductSizeIndex();

      if (httpError.status === 400 || httpError.status === 404 || !listingProduct) return history.push('/404');
      // We should change this in the data

      const thumbnails = this.getThumbnails(listingProduct, activeVariationIndex);
      const orderedThumbnails = (thumbnails && thumbnails.length) && thumbnails.sort((a) => {
        const primarySide = has(listingProduct, 'listingThumbnails')
          ? get(listingProduct, 'listingThumbnails.primary')
          : get(listingProduct, 'thumbnail.primary');
        return a.label === primarySide ? -1 : 1;
      });

      const gallery = (
        <div
          className={ setClass({
            default: 'col--7',
            desktopSm: 'col--6',
            tabletSm: 'mb6',
            mobileLg: 'mb3'
          }) }
        >
          <ListingGallery
            productName={ listingProduct.title }
            imageSet={ orderedThumbnails }
            listing={ listing }
          />
          {bpIsGT('tabletSm') && (
            <Products
              listingSlug={ listingProduct.url }
              storeSlug={ stores.slug }
              getStyles={ getStyles }
              activeProductIndex={ activeProductIndex }
              handleProductChange={ this.handleProductChange }
            />
          )}
        </div>
      );

      const components = {};

      components.privateListing = visibility === 'private' && <PrivateListing />;

      components.listingGallery = bpIsGT('tabletSm') && gallery;

      components.listingDetails = (
        <ListingDetails
          stores={ stores }
          listing={ listing }
          listingProduct={ listingProduct }
          cartData={ userCart }
          activeProductIndex={ activeProductIndex }
          activeVariationIndex={ activeVariationIndex }
          activeSizeIndex={ activeSizeIndex }
          onProductSizeChange={ this.onProductSizeChange }
          onProductVariationChange={ this.onProductVariationChange }
          onFormSubmit={ this.handleAddToCart }
          location={ location }
          trackItemView={ this.trackItemView }
        >
          {bpIsLT('tabletSm') && gallery}
        </ListingDetails>
      );

      components.relatedProducts = (
        <>
          {bpIsLT('tabletSm') && (
            <Products
              listingSlug={ listingProduct.url }
              storeSlug={ stores.slug }
              getStyles={ getStyles }
              activeProductIndex={ activeProductIndex }
              handleProductChange={ this.handleProductChange }
            />
          )}
          {/* We set a key tied to the location here as a workaround to an issue in the Carousel component not handling
              dynamic changes to the products list well (flickity re-arranging nodes upsets react).
              See: https://github.com/metafizzy/flickity/pull/488#issuecomment-339361054 */}
          <RelatedGrid
            key={ `related-grid--${location?.key || 0}` }
            products={ [listingProduct] }
            onClick={ () => this.setState({ pixelSent: false }) }
            recommendedShelfProductEvent="product_page.recommended_products.clicked"
          />
        </>
      );

      return (
        // StoreListings.isFetchingListingProducts
        //   ? <SkeletonListingPage />
        //   : (
        <MainLayout location={ location } storeData={ storeData }>
          <div
            className={ `listingpage  row ${
              get(styles, 'listing.theme') || 'theme--light'
            } ` }
            style={ {
              ...getStyles('listing.bgStyles'),
              ...getStyles('listing.textStyles')
            } }
          >
            <WrappedComponent { ...this.props } { ...components } />
          </div>
        </MainLayout>
      );
      // );
    }
  }

  const {
    array,
    arrayOf,
    number,
    object,
    func,
    bool,
    string,
    shape,
    instanceOf
  } = propTypes;
  BaseComponent.propTypes = {
    storeId: string.isRequired,
    updateCart: func.isRequired,
    userCart: shape({}).isRequired,
    match: shape({}).isRequired,
    fetchListing: func.isRequired,
    setBuyerLocalization: func.isRequired,
    localizationData: shape({}).isRequired,
    clearHttpError: func.isRequired,
    httpError: shape({}).isRequired,
    storeListings: shape({}).isRequired,
    storeData: shape({
      banner_url: string,
      collections: array,
      description: string,
      link_color: string,
      logo_height: number,
      logo_url: string,
      logo_width: number,
      name: string,
      social_identities: object,
      theme_color: string,
      url: string,
      use_logo: bool,
      location: object
    }).isRequired,
    location: shape({
      pathname: string,
      hash: string,
      search: string,
      state: object
    }).isRequired,
    isFetching: bool.isRequired,
    stores: shape({
      banner_url: string,
      description: string,
      link_color: string,
      logo_url: string,
      name: string,
      url: string,
      use_logo: bool,
      theme_color: string,
      social_identities: object,
      logo_width: number,
      logo_height: number,
      collections: arrayOf(object)
    }).isRequired,
    setClass: func.isRequired,
    styles: shape({
      listing: shape({
        theme: string,
        bgColor: string,
        textColor: string
      })
    }).isRequired,
    themeData: shape({
      content: object,
      layout: object,
      meta: object,
      styles: object
    }).isRequired,
    bpIsLT: func.isRequired,
    bpIsGT: func.isRequired,
    getStyles: func.isRequired,
    history: shape({
      push: func
    }).isRequired,
    cookies: instanceOf(Cookies).isRequired,
    isFetchingListing: bool.isRequired,
    fetchCartItems: func.isRequired,
    dispatchSetActiveModal: func.isRequired,
    dispatchFetchCartProduct: func.isRequired,
    dispatchClearHttpError: func.isRequired,
    cartProducts: shape({}).isRequired,
    inventory: shape({}).isRequired
  };

  const mapStateToProps = (state) => {
    const {
      storeListings,
      stores,
      userCart,
      themeData,
      localizationData,
      httpError,
      cartProducts,
      inventory
    } = state;

    return {
      storeListings,
      stores,
      userCart,
      styles: themeData.styles,
      localizationData,
      httpError,
      cartProducts,
      inventory,
      isFetchingListing: storeListings.isFetchingListing,
      isFetchingListingProducts: storeListings.isFetchingListingProducts,
      ...bpProps(state)
    };
  };

  return connect(mapStateToProps, {
    dispatchSetActiveModal: setActiveModal,
    dispatchFetchCartProduct: fetchCartProduct,
    dispatchClearHttpError: clearHttpError,
    setBuyerLocalization,
    fetchListing,
    fetchCartItems
  })(BaseComponent);
}

export default ListingPageWrapper;
