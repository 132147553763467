import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Countdown, { zeroPad } from 'react-countdown';
import './DropHeading.scss';

const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed
}) => {
  if (completed) {
    return <span>Countdown ended!</span>;
  } else {
    return (
      <span className="digits">
        {days}
        {'D : '}
        {zeroPad(hours)}
        {'H : '}
        {zeroPad(minutes)}
        {'M : '}
        {zeroPad(seconds)}
        {'S'}
      </span>
    );
  }
};

export const DropHeading = (props) => {
  const {
    countdown,
    title,
    body,
    beforeImage,
    afterImage
  } = props;

  const [hasEnded, setHasEnded] = useState(false);

  const beforeStyle = {
    backgroundImage: `url(${beforeImage})`
  };

  const afterStyle = {
    backgroundImage: `url(${afterImage})`
  };

  const anchorId = title.trim().replace(/\s+/g, '-').toLowerCase();

  return (
    <div id={ anchorId } className="heading__block">
      <div className="heading__title">
        <h3>{ title }</h3>
        { hasEnded ? (
          <span className="ended">Ended</span>
        ) : (
          <>
            { countdown && (
              <div className="countdown__block">
                <div className="countdown__block--inner">
                  <span>Ends in</span>
                  <Countdown
                    date={ countdown }
                    renderer={ renderer }
                    onComplete={ () => setHasEnded(true) }
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="heading__content" style={ { '--before-img': beforeStyle.backgroundImage, '--after-img': afterStyle.backgroundImage } }>
        { body }
      </div>
    </div>
  );
};

DropHeading.propTypes = {
  countdown: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  beforeImage: PropTypes.string,
  afterImage: PropTypes.string
};

DropHeading.defaultProps = {
  countdown: '',
  title: '',
  body: '',
  beforeImage: '/assets/pdp-objects/ball.png',
  afterImage: '/assets/pdp-objects/lightning.png'
};

export default DropHeading;
