import tracker from 'utils/tracking';
import { useFlags } from 'launchdarkly-react-client-sdk';
import './AgeVerify.scss';
import { useEffect, useState } from 'react';
import { AGE_VERIFY_REDIRECT } from '../../constants';

const AgeVerify = () => {
  const { showAgeVerify } = useFlags();
  const [isVerified, setIsVerified] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isEighteen, setIsEighteen] = useState(false);
  const [isRightState, setIsRightState] = useState(false);
  const fromLocalStorage = window.localStorage.getItem('isVerified');
  const handleYes = () => {
    tracker.track('age_verify.yes.clicked');
    window.localStorage.setItem('isVerified', true);
    setIsVerified(true);
  };
  const handleNo = () => {
    tracker.track('age_verify.no.clicked');
    window.location.href = AGE_VERIFY_REDIRECT;
  };

  if (!showAgeVerify) {
    return null;
  }
  if (isVerified || fromLocalStorage === 'true') {
    return null;
  }

  useEffect(() => {
    if (isEighteen && isRightState) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [isEighteen, isRightState]);

  return (
    <div className="modalOverlay">
      <div className="modal">
        <h2>
          Caution, this site contains sexually explicit content. Kindly certify the
          statements below:
        </h2>
        <form>
          <label htmlFor="checkbox-is-eighteen">
            <input
              type="checkbox"
              id="checkbox-is-eighteen"
              checked={ isEighteen }
              onChange={ () => { setIsEighteen(!isEighteen); } }
            />
            I am 18 years of age or older.
          </label>
          <label htmlFor="checkbox-right-state">
            <input
              type="checkbox"
              id="checkbox-right-state"
              checked={ isRightState }
              onChange={ () => { setIsRightState(!isRightState); } }
            />
            I do not currently reside in the State of Louisiana or Utah.
          </label>
        </form>
        <div className="actions">
          <button
            type="button"
            disabled={ !isChecked }
            className="primary"
            onClick={ handleYes }
          >
            Proceed
          </button>
          <button
            type="button"
            onClick={ handleNo }
          >
            Exit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgeVerify;
