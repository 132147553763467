/* eslint no-shadow: 0 */
import React, { Fragment, useEffect, useMemo } from 'react';
import propTypes from 'prop-types';
import queryString from 'query-string';
import { Cookies, useCookies } from 'react-cookie';
import MainLayout from 'containers/MainLayout';
import tracker from 'utils/tracking';
import tiktok from 'utils/tracking/pixels/tiktok';
import { connect, useDispatch } from 'react-redux';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { PURCHASE_COMPLETE, pushEvent } from 'utils/tracking/gtm';
import get from 'lodash/get';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import { getCookie } from 'utils/cookiesUtils';
import some from 'lodash/some';
import every from 'lodash/every';
import { convertToDollars } from 'utils/moneyUtils';
import { FAILURE, LOADED, NONE } from '../../redux/reducers/orderReducer';
import { fetchOrder, clearCheckout } from '../../redux/actions';
import { USE_CHECKOUT_V2 } from '../../constants';
import './ThankYouPage.scss';

const TRACKING_HOST = process.env.REACT_APP_TRACKING_HOST
  ? process.env.REACT_APP_TRACKING_HOST
  : 'https://teespring.com';

const ThankYouPageWrapper = (WrappedComponent) => {
  const BaseTheme = (props) => {
    const {
      location,
      storeData,
      localizationData,
      fetchOrder,
      order,
      orders,
      orderLoadStatus,
      checkout
    } = props;

    const {
      name, orderNumber, orderLookupNumber, arrivalEstimate, deviceId, cart, contentIds
    } = queryString.parse(
      location.search
    );

    // eslint-disable-next-line no-unused-vars
    const [{ _teespring_partner_attribution: partnerCookie }] = useCookies();

    const sendTrackingPixelPurchase = (amount, currency) => {
      ReactPixel.track('Purchase', {
        content_type: 'product',
        content_ids: [contentIds],
        currency,
        value: convertToDollars(amount)
      });
    };

    const dispatch = useDispatch();
    const orderTotalCost = reduce(orders, (total, cartOrder) => total + get(cartOrder, 'totalCost.amount'), 0);

    useEffect(() => {
      const getOrder = async () => {
        if (orderLoadStatus === NONE) {
          try {
            await fetchOrder(orderLookupNumber || cart);
          } catch (err) {
            tracker.track('thank_you_page.order_fetch_error', {
              error: err,
              order_number: orderLookupNumber || orderNumber
            });
          }
          try {
            tracker.setAmplitudeDeviceId(deviceId);
            tracker.track('thank_you_page.viewed', {
              order_number: orderLookupNumber || orderNumber,
              cart,
              partner: tiktok.trackPartnersCookieInParams(partnerCookie)
            });
          } catch (err) {
            tracker.track('thank_you_page.amplitide_reporting_error', {
              error: err,
              order_number: orderLookupNumber || orderNumber
            });
          }
          try {
            ReactGA.event(
              {
                category: 'ecommerce',
                action: 'purchase',
                label: JSON.stringify({ cart })
              },
              ['default', 'client']
            );

            ReactPixel.pageView();
          } catch (err) {
            tracker.track('thank_you_page.ga_pixel_reporting_error', {
              error: err,
              order_number: orderLookupNumber || orderNumber
            });
          }
        }
      };

      getOrder();
    }, [orderLoadStatus, fetchOrder, cart]);

    useEffect(() => {
      const { cookies } = props;
      const purchaseIsUntracked = !(cookies.get('purchaseTrackId') === get(order, 'lookupNumber'));
      if (orderLoadStatus === LOADED && order && purchaseIsUntracked) {
        if (order.totalCost) {
          sendTrackingPixelPurchase(
            orderTotalCost,
            order.totalCost.currency,
            orderLookupNumber || orderNumber
          );
        }

        const { storeData } = props;

        const baseEvent = {
          eventLabel: 'complete',
          user_id: get(storeData, 'sellerId')
        };

        const gtmEvent = {
          currencyCode: get(order, 'totalCost.currency'),
          purchase: {
            actionField: {
              id: get(order, 'cartId'),
              affiliation: 'TS Microstore',
              revenue: convertToDollars(orderTotalCost),
              tax: convertToDollars(reduce(orders, (total, cartOrder) => total + get(cartOrder, 'tax.amount'), 0)),
              shipping: convertToDollars(reduce(orders, (total, cartOrder) => total + get(cartOrder, 'shippingCost.amount'), 0)),
              coupon: get(order, 'promo')
            },
            products: map(flatMap(orders, cartOrder => get(cartOrder, 'items', [])), (item) => {
              return {
                name: get(item, 'title'),
                id: get(item, 'listingId'),
                price: convertToDollars(get(item, 'price.amount')),
                brand: get(storeData, 'name'),
                variant: `Color: ${get(item, 'color')} | Size: ${get(item, 'size')}`,
                category: get(item, 'productType', ''),
                quantity: get(item, 'quantity'),
                dimension8: get(storeData, 'sellerId'),
                dimension9: get(item, 'listingId'),
                dimension13: get(storeData, 'marketingPixels.gmcMerchantId')
              };
            }),
            enhanced_conversion_data: {
              email: get(checkout, 'customer.email') || get(order, 'customer.email')
            }
          }
        };
        const affiliateCode = getCookie('affiliate');
        pushEvent(PURCHASE_COMPLETE, gtmEvent, order.paymentMethod, baseEvent, affiliateCode);

        // Mark Purchase event as tracked
        cookies.set('purchaseTrackId', get(order, 'lookupNumber'));
      }

      if (orderLoadStatus === FAILURE) {
        sendTrackingPixelPurchase(
          orderTotalCost,
          localizationData.buyer_currency,
          orderLookupNumber || orderNumber
        );
      }

      if (orderLoadStatus === LOADED || orderLoadStatus === FAILURE) {
        dispatch(clearCheckout());
      }
    }, [orderLoadStatus, order]);

    const trackingUrl = useMemo(() => {
      let lookup = orderLookupNumber || orderNumber;

      if (orderLoadStatus === LOADED && orders.length > 0) {
        lookup = orders[0].lookupNumber;
      }

      return `${TRACKING_HOST}/track/order/${lookup}`;
    }, [orders, orderLookupNumber, orderNumber]);

    const ordersItems = flatMap(orders, order => order.items);
    const digitalOrderCondition = { productType: 'Digital product' };
    const hasDigitalOrders = () => some(ordersItems, digitalOrderCondition);
    const hasDigitalOrdersOnly = () => every(ordersItems, digitalOrderCondition);

    const components = {};

    let arrivalEstimateComponent = !hasDigitalOrdersOnly() ? (
      <>
        Your order will arrive between&nbsp;
        <strong>
          {arrivalEstimate}
          .
        </strong>
      </>
    ) : null;

    if (USE_CHECKOUT_V2 && ordersItems.length === 0) {
      arrivalEstimateComponent = (
        <>
          Your order will arrive&nbsp;
          <strong>
            {arrivalEstimate}
            .
          </strong>
        </>
      );
    }

    components.title = (
      <h4>
        {`Thank you ${name}. Your order has been placed.`}
      </h4>
    );

    components.orderSummary = (
      <div className="thankyou__order mb4">
        <p className="mb2">
          Your order number is&nbsp;
          <strong>
            <a className="typ--link" href={ trackingUrl } rel="noreferrer" target="_blank">
              {(orders.length > 0 && orders[0]?.lookupNumber) || orderLookupNumber || orderNumber}
            </a>
          </strong>
        </p>

        <p className="mb2">
          You will receive a confirmation e-mail shortly.
          Once your order has shipped,
          you&apos;ll receive an email with your shipping tracking information.
          Digital products will be delivered immediately via email with a link
          to download your product.
          <br />
          {arrivalEstimateComponent}
        </p>

        { (
          hasDigitalOrders()
        ) && (
          <p className="mb2">
            Your digital download link will be emailed shortly.
          </p>
        ) }

        <p>
          Email us at&nbsp;
          <a
            className="typ--link"
            onClick={ () => tracker.track('thank_you_page.support_email.clicked')
            }
            href="mailto:fanhelp@spri.ng"
          >
            fanhelp@spri.ng
          </a>
          &nbsp;or contact us via Live Chat if you have any questions or would like to make modifications to your order.
        </p>
      </div>
    );

    components.usefulLinks = (
      <Fragment>
        <h4 className="mb1">Useful Links</h4>
        <ul>
          <li>
            <a
              className="typ--link"
              href={ trackingUrl }
              onClick={ () => tracker.track('thank_you_page.track_order.clicked')
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Track your order
            </a>
          </li>
          <li>
            <a
              className="typ--link"
              href="https://teespring.com/login"
              onClick={ () => tracker.track('thank_you_page.log_into_account.clicked')
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Login to your account
            </a>
          </li>
          <li>
            <a
              className="typ--link"
              href="https://answers.teespring.com/"
              onClick={ () => tracker.track('thank_you_page.faq_links.clicked') }
              target="_blank"
              rel="noopener noreferrer"
            >
              Frequently asked questions
            </a>
          </li>

          <li>
            <a
              className="typ--link"
              href="https://sprisupport.zendesk.com/hc/en-us/articles/12171053036685-Returns-And-Cancellations/"
              onClick={ () => tracker.track('thank_you_page.faq_links.clicked') }
              target="_blank"
              rel="noopener noreferrer"
            >
              Refunds and Cancellations
            </a>
          </li>
        </ul>
      </Fragment>
    );

    return (
      <MainLayout location={ location } storeData={ storeData }>
        <WrappedComponent
          { ...props }
          { ...components }
          trackingUrl={ trackingUrl }
        />
      </MainLayout>
    );
  };

  const {
    shape, string, number, bool, func, instanceOf, arrayOf
  } = propTypes;

  BaseTheme.propTypes = {
    fetchOrder: func.isRequired,
    order: shape({}).isRequired,
    orders: arrayOf(shape({})).isRequired,
    orderLoadStatus: string.isRequired,
    cookies: instanceOf(Cookies).isRequired,
    storeData: shape({
      banner_url: string,
      collections: arrayOf(shape({})),
      description: string,
      link_color: string,
      logo_height: number,
      logo_url: string,
      logo_width: number,
      name: string,
      social_identities: shape({}),
      theme_color: string,
      url: string,
      use_logo: bool,
      location: shape({})
    }).isRequired,
    localizationData: shape({
      buyer_currency: string
    }).isRequired,
    location: shape({
      pathname: string,
      hash: string,
      search: string,
      state: shape({})
    }).isRequired,
    checkout: shape({
      email: string
    }).isRequired
  };

  const mapStateToProps = state => ({
    order: state.order.data[0],
    orders: state.order.data,
    orderLoadStatus: state.order.loadStatus,
    checkout: state.checkout
  });

  return connect(mapStateToProps, { fetchOrder })(BaseTheme);
};

export default ThankYouPageWrapper;

